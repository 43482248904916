import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router';
import proceedToLogin from './loginReducer';
import contextProvider from './UserContextReducer.js';
import userRoutesReducer from './UserRoutesReducer';
import {routesReducer} from './admin/RoutesReducer';
import {adminLogReducer} from './admin/AdminLogReducer';
import {orgUnitsReducer} from './admin/OrgUnitsReducer';
import {providersReducer} from './admin/ProvidersReducer';
import {permissionsReducer} from './admin/PermissionsReducer';
import {newTenantReducer, createNewTenantReducer, loadTenantsReducer} from './admin/NewTenantReducer';
import {accessRolesReducer, addAccessRoleReducer, accessRolesUpdate} from './admin/AccessRolesReducer';
import {pensionFundReducer} from './admin/PensionFundReducer';
import { reportReducer } from './admin/ReportReducer';
import usersReducer from './user/UsersReducer';
import storage from "redux-persist/lib/storage";
import { reducer as notifReducer } from 'redux-notifications';
import {persistReducer} from 'redux-persist'
import {statisticsReducer} from "./statistics/statisticsReducer";


const persistConfig = {
    key: 'auth',
    storage,
};
const persistContextConfig = {
    key: 'context',
    storage,
};
const persistLocalizeConfig = {
    key: 'localize',
    storage,
};
const routesProviderConfig = {
    key: 'routesProvider',
    storage,
};

export default history => (state, action) => {
    if (action.type === 'LOGGED_OUT') {
        state = undefined
    }

    return appReducer(history)(state, action);
}

const appReducer = history =>
    combineReducers({
        router: connectRouter(history),
        notifs: notifReducer,
        routesManager: routesReducer,
        loadTenantsReducer: loadTenantsReducer,
        newTenantReducer: newTenantReducer,
        orgUnitsReducer: orgUnitsReducer,
        adminLogReducer: adminLogReducer,
        providersReducer: providersReducer,
        statisticsReducer: statisticsReducer,
        usersReducer: usersReducer,
        accessRolesReducer: accessRolesReducer,
        pensionFundReducer: pensionFundReducer,
        createNewTenantReducer: createNewTenantReducer,
        accessRolesUpdate: accessRolesUpdate,
        addAccessRoleReducer: addAccessRoleReducer,
        permissionsReducer: permissionsReducer,
        routesProvider: persistReducer(routesProviderConfig, userRoutesReducer),
        context: persistReducer(persistContextConfig, contextProvider),
        auth: persistReducer(persistConfig, proceedToLogin),
        reports: reportReducer
    });
