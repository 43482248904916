import { render } from 'react-dom';
import { createBrowserHistory } from 'history';
import { Route, Switch, Redirect } from 'react-router';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Provider } from 'react-redux';
import ES6promise from 'es6-promise';
import ES6objectAssign from 'es6-object-assign';
import 'babel-polyfill';
import createRootReducer from './reducers';
import { ConnectedRouter, routerMiddleware } from 'connected-react-router';
import { applyMiddleware, compose, createStore } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import storage from 'redux-persist/lib/storage';
import AuthLayout from './layouts/Auth/Auth.jsx';
import AdminLayout from './layouts/Admin/Admin.jsx';
import 'bootstrap/dist/css/bootstrap.css';
import './assets/scss/paper-dashboard.scss';
import './assets/demo/demo.css';
import thunk from 'redux-thunk';
import rootSaga from './actions/rootSaga';
import NetworkService from './components/Network/NetworkService';
import { PersistGate } from 'redux-persist/integration/react';
import createSagaMiddleware from 'redux-saga';
import React from 'react';
import { lightBlue, pink } from '@mui/material/colors';

ES6promise.polyfill();
ES6objectAssign.polyfill();
const authPersistConfig = {
  key: 'root',
  storage: storage,
  whitelist: ['auth', 'context', 'localize', 'routesProvider'],
};

const history = createBrowserHistory();
const persistedReducers = persistReducer(
  authPersistConfig,
  createRootReducer(history)
);
const sagaMiddleware = createSagaMiddleware();

const composeEnhancers =
  (typeof window !== 'undefined' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

let store = createStore(
  persistedReducers,
  composeEnhancers(
    applyMiddleware(thunk, sagaMiddleware, routerMiddleware(history))
  )
);
sagaMiddleware.run(rootSaga);

export { store }

const persistedStore = persistStore(store);

const theme = createTheme({
    typography: {
        "fontFamily": `"Frutiger", "Roboto", "Helvetica", "Arial", sans-serif`,
        "fontSize": 14,
        "fontWeightLight": 300,
        "fontWeightRegular": 400,
        "fontWeightMedium": 500,
        body1: {
            "fontSize": 14
        }
    },
    palette: {
        primary: lightBlue,
        secondary: pink,
    },
});

NetworkService.setupInterceptors(history, store);

render(
  <ThemeProvider theme={theme}>
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Provider store={store}>
          <PersistGate loading={null} persistor={persistedStore}>
            <ConnectedRouter history={history}>
              <Switch>
                <Route
                  path="/auth"
                  render={props => <AuthLayout {...props} />}
                />
                <Route
                  path="/admin"
                  render={props => <AdminLayout {...props} />}
                />
                <Redirect from="*" to="/auth/login" />
              </Switch>
            </ConnectedRouter>
          </PersistGate>
      </Provider>
    </LocalizationProvider>
  </ThemeProvider>,
  document.getElementById('root')
);
