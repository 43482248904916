import React from "react";
import classnames from "classnames";
import {
    Button,
    Collapse,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    Navbar,
    Nav,
    Container
} from "reactstrap";
import { removeUserFromContext, logout } from "../../actions";
import { connect } from "react-redux";
import Translate from '../common/Translate';
import { LANGUAGES_TO_DISPLAY } from "../../variables/constants";
import {
    updateUserDetailRequest
} from "../../actions/userActions"

class AdminNavbar extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            collapseOpen: false,
            color: "navbar-transparent"
        };
    }
    componentDidMount() {
        window.addEventListener("resize", this.updateColor);
    }
    componentDidUpdate(e) {
        if (
            window.outerWidth < 993 &&
            e.history.location.pathname !== e.location.pathname &&
            document.documentElement.className.indexOf("nav-open") !== -1
        ) {
            document.documentElement.classList.toggle("nav-open");
        }
    }
    // function that adds color white/transparent to the navbar on resize (this is for the collapse)
    updateColor = () => {
        if (window.innerWidth < 993 && this.state.collapseOpen) {
            this.setState({
                color: "bg-white"
            });
        } else {
            this.setState({
                color: "navbar-transparent"
            });
        }
    };
    // this function opens and closes the sidebar on small devices
    toggleSidebar = () => {
        document.documentElement.classList.toggle("nav-open");
    };

    changeLanguage = (e) => {
        const { context, updateUser } = this.props;
        const code = e.currentTarget.attributes.value.nodeValue;
        updateUser({ ...context, language: code });
    };

    logoutClick = (event) => {
        const { logout, removeUserFromContext } = this.props;
        event.preventDefault();
        logout();
        removeUserFromContext();
    };
    // this function opens and closes the collapse on small devices
    // it also adds navbar-transparent class to the navbar when closed
    // ad bg-white when opened
    toggleCollapse = () => {
        let newState = {
            collapseOpen: !this.state.collapseOpen
        };
        if (!this.state.collapseOpen) {
            newState["color"] = "bg-white";
        } else {
            newState["color"] = "navbar-transparent";
        }
        this.setState(newState);
    };
    render() {
        return (
            <Navbar
                className={classnames("navbar-absolute fixed-top", this.state.color)}
                expand="lg"
            >
                <Container fluid>
                    <div className="navbar-wrapper">
                        <div className="navbar-minimize">
                            <Button
                                className="btn-icon btn-round"
                                color="default"
                                id="minimizeSidebar"
                                onClick={this.props.handleMiniClick}
                            >
                                <i className="nc-icon nc-minimal-right text-center visible-on-sidebar-mini" />
                                <i className="nc-icon nc-minimal-left text-center visible-on-sidebar-regular" />
                            </Button>
                        </div>
                        <div
                            className={classnames("navbar-toggle", {
                                toggled: this.state.sidebarOpen
                            })}
                        >
                            <button
                                className="navbar-toggler"
                                type="button"
                                onClick={this.toggleSidebar}
                            >
                                <span className="navbar-toggler-bar bar1" />
                                <span className="navbar-toggler-bar bar2" />
                                <span className="navbar-toggler-bar bar3" />
                            </button>
                        </div>
                    </div>
                    <button
                        aria-controls="navigation-index"
                        aria-expanded={this.state.collapseOpen}
                        aria-label="Toggle navigation"
                        className="navbar-toggler"
                        // data-target="#navigation"
                        data-toggle="collapse"
                        type="button"
                        onClick={this.toggleCollapse}
                    >
                        <span className="navbar-toggler-bar navbar-kebab" />
                        <span className="navbar-toggler-bar navbar-kebab" />
                        <span className="navbar-toggler-bar navbar-kebab" />
                    </button>
                    <Collapse
                        className="justify-content-end"
                        navbar
                        isOpen={this.state.collapseOpen}
                    >
                        <Nav navbar>
                            <UncontrolledDropdown className="btn-rotate" nav>
                                <DropdownToggle
                                    aria-haspopup={true}
                                    caret
                                    color="default"
                                    data-toggle="dropdown"
                                    id="navbarDropdownMenuLink"
                                    nav
                                >
                                <>
                                    <img
                                        alt="..."
                                        src={this.props.context.language && require(`../../assets/img/flags/${this.props.context.language.toUpperCase()}.png`)}
                                    />
                                    <p>
                                        <span className="d-md-block px-2">{this.props.context.language?.toUpperCase()}</span>
                                        <span className="d-lg-none d-md-block">Change Language</span>
                                    </p>
                                </>

                                </DropdownToggle>
                                <DropdownMenu aria-labelledby="navbarDropdownMenuLink" right>
                                    {LANGUAGES_TO_DISPLAY.map(item => (
                                        <DropdownItem
                                            key={item.value}
                                            value={item.value}
                                            onClick={this.changeLanguage}
                                        >
                                            <img className="pr-2"
                                                alt="..."
                                                src={require(`../../assets/img/flags/${item.flag}`)}
                                            />
                                            <Translate id={`common.languages.${item.value}`} />
                                        </DropdownItem>
                                    ))}
                                </DropdownMenu>
                            </UncontrolledDropdown>
                            <UncontrolledDropdown className="btn-rotate" nav>
                                <DropdownToggle
                                    aria-haspopup={true}
                                    caret
                                    color="default"
                                    data-toggle="dropdown"
                                    id="navbarDropdownMenuLink"
                                    nav
                                >
                                    <i className="nc-icon nc-button-power" />
                                    <p>
                                        <span className="d-lg-none d-md-block">Menu</span>
                                    </p>
                                </DropdownToggle>
                                <DropdownMenu aria-labelledby="navbarDropdownMenuLink" right>
                                    <DropdownItem
                                        href="#logout"
                                        onClick={this.logoutClick}
                                    >
                                        Logout
                                    </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </Nav>
                    </Collapse>
                </Container>
            </Navbar>
        );
    }
}


const mapStateToProps = (state = []) => ({
    context: state.context.context
});

const mapDispatchToProps = dispatch => ({
    removeUserFromContext: () =>
        dispatch(removeUserFromContext()),
    logout: () =>
        dispatch(logout()),
    updateUser: (user) => 
        dispatch(updateUserDetailRequest(user))
});

export default connect(mapStateToProps, mapDispatchToProps)(
    AdminNavbar
);
