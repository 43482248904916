import React from "react";
// javascript plugin used to create scrollbars on windows
// import PerfectScrollbar from "perfect-scrollbar";
import { Redirect, Switch } from "react-router-dom";

import AuthorizedRoute from "../../AuthorizedRoute";
import AdminNavbar from "../../components/Navbars/AdminNavbar.jsx";
import Footer from "../../components/Footer/Footer.jsx";
import Sidebar from "../../components/Sidebar/Sidebar.jsx";

import { connect } from "react-redux";
import { logout } from "../../actions";
import Notification from "../../components/common/Notifications";
import FixedPlugin from "../../components/FixedPlugin/FixedPlugin";
import IdleNotification from "../../components/Network/IdleNotification";
import { SERVER_ENVIRONMENT, THEME } from '../../variables/constants'

class Admin extends React.Component {
    constructor (props) {
        super(props);
        const onMissingTranslation = ({ translationId, languageCode }) => {
            return `Translation missing ${translationId} - ${languageCode}`;
        };
        const getTheme = () => {
            switch (document.location.hostname) {
                case SERVER_ENVIRONMENT.PRODUCTION:
                    return THEME.PRODUCTION;
                case SERVER_ENVIRONMENT.STAGING:
                    return THEME.STAGING;
                default:
                    return process.env.REACT_APP_THEME || THEME.DEVELOPMENT;
            }
        };        
        // const { initialize } = this.props;
        // initialize({
        //     languages: [
        //         { name: 'English', code: 'en' },
        //         { name: 'German', code: 'de' },
        //         { name: 'French', code: 'fr' }
        //     ],
        //     translation: props.translation,
        //     options: {
        //         defaultLanguage: props.language,
        //         onMissingTranslation: onMissingTranslation,
        //         renderToStaticMarkup: false
        //     },
        // });

        this.state = {
            backgroundColor: getTheme(),
            activeColor: "info",
            sidebarMini: false,
        };
    }

    componentDidMount() {
        if (navigator.platform.indexOf("Win") > -1) {
            document.documentElement.className += " perfect-scrollbar-on";
            document.documentElement.classList.remove("perfect-scrollbar-off");
            // ps = new PerfectScrollbar(this.refs.mainPanel);
        }
    }

    componentWillUnmount() {
        if (navigator.platform.indexOf("Win") > -1) {
            // ps.destroy();
            document.documentElement.className += " perfect-scrollbar-off";
            document.documentElement.classList.remove("perfect-scrollbar-on");
        }
    }


    componentDidUpdate(e) {
        if (e.history.action === "PUSH") {
            window.scrollTo(0, 0)
        }
    }

    getRoutes = routes => {
        return routes.map((prop, key) => {
            if (prop.collapse) {
                return this.getRoutes(prop.views);
            }
            if (prop.layout === "/admin") {
                return (
                    <AuthorizedRoute
                        path={prop.layout + prop.path}
                        compPath={prop.compPath}
                        component={prop.component}
                        key={key}
                    />
                );
            } else {
                return null;
            }
        });
    };

    handleActiveClick = color => {
        this.setState({ activeColor: color });
    };


    handleBgClick = color => {
        this.setState({ backgroundColor: color });
    };

    handleMiniClick = () => {
        if (document.body.classList.contains("sidebar-mini")) {
            this.setState({ sidebarMini: false });
        } else {
            this.setState({ sidebarMini: true });
        }
        document.body.classList.toggle("sidebar-mini");
    };

    render() {
        const { routes } = this.props;
        return (
            <div className="wrapper">

                <Sidebar
                    {...this.props}
                    routes={routes}
                    bgColor={this.state.backgroundColor}
                    activeColor={this.state.activeColor}
                />
                <div className="main-panel" ref="mainPanel">
                    <AdminNavbar {...this.props} handleMiniClick={this.handleMiniClick} />
                    <Notification />
                    <Switch>
                        {this.getRoutes(routes)}
                        <AuthorizedRoute
                            path='/admin/error'
                            mini='ER'
                            compPath='ErrorPage'
                        />
                        <AuthorizedRoute
                            path='/admin/my-profile'
                            mini='MP'
                            compPath='profile/MyProfile'
                        />
                        <Redirect from='*' to='/auth/login' />
                    </Switch>
                    {// we don't want the Footer to be rendered on full screen maps page
                        this.props.location.pathname.indexOf("full-screen-map") !==
                            -1 ? null : (
                                <Footer fluid />
                            )}
                </div>
                <IdleNotification />
                <FixedPlugin
                    bgColor={this.state.backgroundColor}
                    activeColor={this.state.activeColor}
                    sidebarMini={this.state.sidebarMini}
                    handleActiveClick={this.handleActiveClick}
                    handleBgClick={this.handleBgClick}
                    handleMiniClick={this.handleMiniClick}
                />
            </div>
        );
    }
}

const mapStateToProps = (state = []) => ({
    routes: state.routesProvider.routes,
    translation: state.context.translation,
    language: state.context.context.language
});

const mapDispatchToProps = dispatch => ({
    logout: () =>
        dispatch(logout()),
    initialize: (payload) => dispatch(initialize(payload))
});


export default connect(mapStateToProps, mapDispatchToProps)(
    Admin
);
