import React from 'react';
import {useSelector} from 'react-redux';
import Selectors from "../../selectors/selectors";
import { LANGUAGES } from '../../variables/constants';

const Translate = (props) => {
  const translate = useSelector(Selectors.getTranslation);
  const {
        id
    } = props;
    return (
        <span>
            {translate(id)}
        </span>
    );
};
export default (Translate);
