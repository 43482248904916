import React from "react";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Form,
    Container,
    Col,
    Row
} from "reactstrap";
import { Redirect, withRouter } from "react-router";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import TextField from "@mui/material/TextField/TextField";
import { requestPasswordReset } from '../../actions/index';
import MessageNotification from "../../components/common/MessageNotification";

class RequestPasswordReset extends React.Component {

    componentDidMount() {
        document.body.classList.toggle("login-page");
    }

    componentWillUnmount() {
        document.body.classList.toggle("login-page");
    }

    constructor (props) {
        super(props);
        this.state = {
            email: '',
            message: ''
        };

        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    onSubmit(e) {
        e.preventDefault();
        this.props.doRequestPasswordReset(
            this.state.email
        );
        this.setState({ email: '', message: {type: 'info', msg: 'server.resetPasswordSent'} });
    }

    render() {
        const { logout } = this.props;
        if (sessionStorage.getItem('token') === null && this.props.logged) {
            logout();
        }
        if (this.props.logged && sessionStorage.getItem("token") !== null) {
            return <Redirect to="/admin/dataView" />
        } else {
            return (
                <div className="login-page">
                    <Container>
                        <Row>
                            <Col className="ml-auto mr-auto" lg="4" md="6">
                                <Form onSubmit={this.onSubmit}className="form"  >
                                    <Card  className="card-login">
                                        <CardHeader>
                                            <CardHeader>
                                                <h3 className="header text-center">Forgot Password?</h3>
                                            </CardHeader>
                                        </CardHeader>
                                        <CardBody>
                                            <MessageNotification messageKey={this.state.message} color='info' />
                                            <p className="text-justify">If you have forgotten your password, we can email you a link to help you reset it. Enter your email address and click 'Send'</p>
                                            <TextField
                                                value={this.state.email}
                                                name="email"
                                                type="email"
                                                label="Email"
                                                onChange={this.onChange}
                                                margin="normal"
                                                fullWidth
                                            />
                                            <br />
                                        </CardBody>
                                        <CardFooter>
                                            <Button
                                                block
                                                className="btn-round mb-3"
                                                color="info"
                                                type="submit"
                                                disabled={!this.state.email}
                                                onClick={this.onSubmit}
                                            >
                                                Send
                                            </Button>
                                            <br />
                                            <Link to="/auth/login" >
                                                <div className="text-center">Back to login</div>
                                            </Link>
                                        </CardFooter>
                                    </Card>
                                </Form>
                            </Col>
                        </Row>
                    </Container>
                    <div
                        className="full-page-background"
                        style={{
                            backgroundImage: `url(${require("../../assets/img/bg/trsnp.jpg")})`
                        }}
                    />
                </div>
            );
        }
    }
}

const mapStateToProps = (state = []) => ({
    logging: state.auth.logging,
    logged: state.auth.logged
});

const mapDispatchToProps = dispatch => ({
    doRequestPasswordReset: (email) =>
        dispatch(requestPasswordReset(email))
});

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(RequestPasswordReset)
);
