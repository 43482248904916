import React from "react";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Form,
    Container,
    Col,
    Row
} from "reactstrap";
import {Redirect, withRouter} from "react-router";
import {Link} from "react-router-dom";
import { connect } from "react-redux";
import TextField from "@mui/material/TextField/TextField";
import {loginRequest, logout} from '../../actions/index';
import MessageNotification from "../../components/common/MessageNotification";

class Login extends React.Component {
    componentDidMount() {
        document.body.classList.toggle("login-page");
    }

    componentWillUnmount() {
        document.body.classList.toggle("login-page");
    }

    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            disabled1: false,
            disabled2: false,
        };

        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    onChange(e) {
        this.setState({[e.target.name]: e.target.value});
    }

    onSubmit(e) {
        this.setState({
            disabled1: true,
        });
        e.preventDefault();
        this.props.doLogin(
            this.state.username,
            this.state.password
        );
    }

    render() {
        const {logout, logging, errorMsg} = this.props;
        if (sessionStorage.getItem('token') === null && this.props.logged) {
            logout();
        }
        if (this.props.logged && sessionStorage.getItem("token") !== null) {
            if (this.props.context && this.props.context.providerOnly) {
                return <Redirect to="/admin/inputData"/>
            } else {
                return <Redirect to="/admin/pensionSearch" />
            }
        } else {
            return (
                <div className="login-page">
                    <Container>
                        <Row>
                            <Col className="ml-auto mr-auto" lg="4" md="6">
                                <Form onSubmit={this.onSubmit} className="form"  >
                                    <Card className="card-login">
                                        <CardHeader>
                                            <CardHeader>
                                                <h3 className="header text-center">Login</h3>
                                            </CardHeader>
                                        </CardHeader>
                                        <CardBody className="text-center">
                                            <MessageNotification messageKey={errorMsg} color='info' />

                                            <TextField
                                                value={this.state.username}
                                                label="Email"
                                                name="username"
                                                margin="normal"
                                                onChange={this.onChange}
                                            />
                                            <br/>
                                            <TextField
                                                value={this.state.password}
                                                name="password"
                                                type="password"
                                                label="Password"
                                                onChange={this.onChange}
                                                autoComplete="current-password"
                                                margin="normal"
                                            />
                                            <br/>
                                            <Link to="/auth/requestPasswordReset" >
                                                Forgot password?
                                            </Link>
                                            <br/>
                                        </CardBody>
                                        <CardFooter>
                                            <Button
                                                block
                                                className="btn-round mb-3"
                                                color="info"
                                                type="submit"
                                                disabled={logging}
                                                onClick={this.onSubmit}
                                            >
                                                Login
                                            </Button>
                                        </CardFooter>
                                    </Card>
                                </Form>
                            </Col>
                        </Row>
                    </Container>
                    <div
                        className="full-page-background"
                        style={{
                            backgroundImage: `url(${require("../../assets/img/bg/trsnp.jpg")})`
                        }}
                    />
                </div>
            );
        }
    }
}

const mapStateToProps = (state = []) => ({
    errorMsg: state.auth.msg,
    logging: state.auth.logging,
    logged: state.auth.logged,
    context: state.context.context
});

const mapDispatchToProps = dispatch => ({
    doLogin: (username, password) =>
        dispatch(loginRequest(username, password)),
    logout: () => dispatch(logout())
});

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(Login)
);
